import useResizeObserver from '@react-hook/resize-observer'
import {ChevronLeft, ChevronRight} from "@styled-icons/feather";
import {Children, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {Heading3, Paragraph} from './BaseComponents';


const Sections = styled.div`
  margin: 10px 0;
  ${breakpoint('sm')` // 28 : 36
        margin: 10px 0;
    `}
  ${breakpoint('xl')` // 30 : 36
        margin: 10px 0;
    `}
`;

const SectionArrowLeft = styled.div`
  position: absolute;
  display: ${props => props.visible === true ? "flex" : "none"};
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 4%;
  top: 0;
  left: 0;
  bottom: 0;
  transition-duration: 0.25s;
  cursor: pointer;

  :hover {
    background-color: #005854;
    width: 6%;
  }

  z-index: 1;
`;

const SectionArrowRight = styled.div`
  position: absolute;
  display: ${props => props.visible === true ? "flex" : "none"};
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 4%;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  transition-duration: 0.25s;

  :hover {
    background-color: #005854;
    width: 6%;
  }

  z-index: 1;
`;

const Section = styled.div`
  position: relative;
`;

const SectionHeader = styled(Heading3)`
  position: relative;
  //margin: 10px;
  margin: 20px 4% 8px calc(4% + 5px);
  ${breakpoint('sm')` // 28 : 36
    `}
`;

const SectionMask = styled.div`
  position: relative;
  display: flex;
  overflow-x: scroll;
  width: 92vw;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;


const SectionListContainer = ({children, className}) => {
    const width = useSelector(state => {
        const {window: {width}} = state;
        return width * 0.92;
    });
    const numItems = Children.count(children);
    const maskRef = useRef(null);
    const [page, setPage] = useState({
        numPages: 0,
        itemsVisible: 0,
        itemWidth: 0,
    });
    const [position, setPosition] = useState( 0 );

    useLayoutEffect(() => {
        const {height} = maskRef.current.getBoundingClientRect();
        const itemsVisible = Math.round(width / (height * 16 / 9));
        const itemWidth = width / itemsVisible;
        const numPages = Math.ceil(numItems / itemsVisible);
        //console.log(numItems, itemWidth, itemsVisible)
        setPage({
            numPages,
            itemsVisible,
            itemWidth,
        })
    }, [maskRef,numItems,width])

    useResizeObserver(maskRef, (entry) => {
        setPosition(0)
    })

    useEffect(() => {
        scrollTo(position * width);
    });

    const scrollTo = (pos, animated = true) => {
        maskRef.current.scrollTo({
            top: 0,
            left: pos,
            behavior: animated ? "smooth" : "auto",
        })
    }

    const onLeftClick = () => {
        setPosition(position > 0 ? position - 1 : position);
    }

    const {numPages} = page;
    const onRightClick = () => {
        setPosition(position < numPages - 1 ? position + 1 : position);
    }

    const onScroll = (val) => {
        // const offset = maskRef.current.scrollLeft;
        // console.log((offset / width) - 0.5);
    }

    return (
        <div className={className}>
            <SectionArrowLeft onClick={onLeftClick} visible={position > 0}>
                <ChevronLeft/>
            </SectionArrowLeft>
            <SectionMask ref={maskRef} onScroll={onScroll}>
                {children}
            </SectionMask>
            <SectionArrowRight onClick={onRightClick} visible={position < numPages - 1}>
                <ChevronRight/>
            </SectionArrowRight>
        </div>
    )
}

const SectionList = styled(SectionListContainer)`
  position: relative;
  display: flex;
  margin: 0;
  padding: 0 4%;
`

const Container = styled.div`
  position: relative;
  display: flex;
  aspect-ratio: 16 / 9;
  width: calc((100vw / 1.25) - 10px);
  ${breakpoint('sm')`
        width: calc(92vw / 3);
    `}
  ${breakpoint('md')`
        width: calc(92vw / 3);
    `}
  ${breakpoint('lg')`
        width: calc(92vw/ 4);
    `}
  ${breakpoint('xl')`
        width: calc(92vw / 5);
    `}
  `;

const ThumbContainer = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 0 .3vw;
`;

const ThumbMask = styled.div`
  overflow: hidden;
  height: 100%;
  position: relative;
  border-radius: 8px;
`;

const ThumbImage = styled.img`
  //width: 100%;
  height: 100%;
  object-fit: cover;
  width: calc((100vw / 1.25) - 10px);
  ${breakpoint('sm')`
        width: calc(92vw / 3);
    `}
  ${breakpoint('md')`
        width: calc(92vw / 3);
    `}
  ${breakpoint('lg')`
        width: calc(92vw/ 4);
    `}
  ${breakpoint('xl')`
        width: calc(92vw / 5);
    `}  
`;

const ThumbParagraph = styled(Paragraph)`
  transform: translateY(-100%);
  padding: 8px 12px;
  background: linear-gradient(#00000000, black);
  //background-color: #005854;
`;

const SectionListItem = ({data}) => {
    const {title, thumbURI, playoutId} = data;
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/video/${playoutId}`);
    }

    return (
        <Container>
            <ThumbContainer onClick={onClick}>
                <ThumbMask>
                    <ThumbImage src={thumbURI} alt=""/>
                    <ThumbParagraph>{title}</ThumbParagraph>
                </ThumbMask>
            </ThumbContainer>
        </Container>
    )
}

export {Sections, Section, SectionHeader, SectionList, SectionListItem};
