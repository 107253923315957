import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Navigation = styled.div`
    width: 100%;
    height: 42px;
    padding: 0 16px;
    background: rgb(0,50,51);
    background: linear-gradient(90deg, rgba(0,50,51,1) 0%, rgba(1,88,85,1) 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${breakpoint('sm')` // 18
        background: linear-gradient(90deg, rgba(0,50,51) 0%, rgba(1,88,85,0.7) 100%);
        position: absolute;
        top: 0;
        padding: 0 16px;
        height: 48px;
    `}
    ${breakpoint('xl')` // 24
        padding: 0 16px;
        height: 78px;
    `}
`;

const SrcSetImage = styled.img.attrs(props => ({
    src: `${props.src}.png`,
    srcSet: `${props.src}.png 1x, ${props.src}@2x.png 2x, ${props.src}@3x.png 3x`,
}))`
    //height: ${props => props.height};
`;

const DuTubeLogo = styled(SrcSetImage)`
    height: 15px;
    ${breakpoint('sm')` // 18
        height: 20px;
    `}
    ${breakpoint('xl')` // 24
        height: 20px;
    `}  
`;

const DupixentLogo = styled(SrcSetImage)`
    height: 20.25px;
    ${breakpoint('sm')` // 18
        height: 27px;
    `}
    ${breakpoint('xl')` // 24
        height: 27px;
    `}
`;

const Header = () => {
    return (
        <Navigation>
            <DuTubeLogo src="/images/DuTubeLogo" />
            <DupixentLogo src="/images/DupixentLogo" />
        </Navigation>
    )
}

export default Header;
