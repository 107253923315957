import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {Heading1, Heading3, Paragraph} from "./BaseComponents";

const Slider = ({className, children}) => {
    // const count = Children.count(children);
    return (
        <div className={className}>
            {children}
        </div>
    )
}

const FeatureSlider = styled(Slider)`
    display: none;
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    justify-content: center;
    background: black;
    ${breakpoint('sm')` // 18
        display: block;
    `}
    ${breakpoint('xl')` // 24
        aspect-ratio: 21 / 9;
    `}
`;

const SlideImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;   
`;

const Slide = ({src, children, className}) => {
    return (
        <div className={className}>
            <SlideImage src={src} />
            {children}
        </div>
    )
}

const FeatureSlide = styled(Slide)`
    position: absolute;   
    width: 100%;
    height: 100%;
`;

const FeatureContentContainer = styled.div`
    position: absolute;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    bottom: 0;
    ${breakpoint('sm')` // 18
        margin: 0 0 4vw 4vw;
        max-width: 500px;
    `}
    ${breakpoint('xl')` // 24
        margin: 0 0 8vw 4vw;
        max-width: 600px;
    `}
`;

const FeaturedHeading = styled(Heading1)`
  margin-bottom: 20px;
  text-transform: uppercase;
  ${breakpoint('sm')` // 28 : 36
        margin-bottom: 15px;
    `}
  ${breakpoint('xl')` // 30 : 36
        margin-bottom: 20px;
    `}
`;

const FeaturedSpeaker = styled(Heading3)`
  margin-bottom: 20px;
  ${breakpoint('sm')` // 28 : 36
        margin-bottom: 40px;
    `}
  ${breakpoint('xl')` // 30 : 36
        margin-bottom: 50px;
    `}
`;

const FeaturedParagraph = styled(Paragraph)`
    margin-bottom: 14px;
`;



export {FeatureSlider, FeatureSlide, FeaturedHeading, FeaturedSpeaker, FeaturedParagraph, FeatureContentContainer};
