import {EVENTS} from "../../constants";
import produce from 'immer';

const STATE = []

function eventReducer(state = STATE, action) {
    const {type, payload} = action;
    switch (type) {
        case EVENTS.ADD:
            const {category, eventType, eventTitle, eventDay} = payload;
            const typeIndex = state.findIndex(el => el.type === eventType)
            console.log(state);
            if(typeIndex > -1) {
                const {events} = state[typeIndex];
                const eventIndex = events.findIndex(el => el.title === eventTitle);
                if(eventIndex > -1) {
                    return produce(state, draft => {
                        const {dayCount, categories} = draft[typeIndex].events[eventIndex];
                        if(dayCount < eventDay) {
                            draft[typeIndex].events[eventIndex].dayCount = eventDay;
                        }
                        const categoryIndex = categories.findIndex(el => el === category);
                        if(categoryIndex === -1) {
                            draft[typeIndex].events[eventIndex].categories.push(category);
                        }
                    })
                } else {
                    return produce(state, draft => {
                        draft[typeIndex].events.push({
                            "title": eventTitle,
                            "dayCount": eventDay,
                            "categories": [category],
                        })
                    })
                }
            } else {
                return [...state, {
                    "type": eventType,
                    "events": [
                        {
                            "title": eventTitle,
                            "dayCount": eventDay,
                            "categories": [category],
                        }
                    ]
                }]
            }
        default:
            return state
    }
}

export default eventReducer
