import {VIDEOS} from "../../constants";

const STATE = {
    loading: true,
    error: false,
    files: [],
}

function videoReducer(state = STATE, action) {
    const {type, payload} = action;
    switch (type) {
        case VIDEOS.LOAD:
            return {...state, loading: true, error: false}
        case VIDEOS.LOAD_ERROR:
            return {...state, loading: false, error: true}
        case VIDEOS.LOAD_SUCCESS:
            return {...state, loading: false, files: payload}
        default:
            return state
    }
}

export default videoReducer
