import {X, ArrowDownCircle} from '@styled-icons/feather';
import {CloudArrowDown} from "@styled-icons/bootstrap";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import styled, {keyframes} from "styled-components";
import {Button, ExternalLinkButton, Heading3, Paragraph} from "../components/BaseComponents";
import {FeaturedHeading, FeaturedParagraph} from "../components/FeatureSlider";

const scaleInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Cover = styled.div`
  position: fixed;
  background-color: #000000a0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: ${fadeInAnimation};
  animation-duration: 0.25s;
  animation-iteration-count: 1;
  z-index: 10;
`;

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 2%;
  background: #003233;
  animation-name: ${scaleInAnimation};
  animation-duration: 0.25s;
  animation-iteration-count: 1;
  border-radius: 1%;
  height: 94vh;
  overflow-y: hidden;
`;

const Scrollable = styled.div`
  overflow-y: scroll;
  height: calc(94vh - 60px);
  padding-bottom: 80px;
`;

const Header = styled.div`
  display: flex;
  padding: 0 10px;
  align-items: center;
  height: 60px;
  z-index: 100;
`;

const CloseX = styled(X)`
  height: 40px;
  cursor: pointer;
`;

const DownloadArrow = styled(CloudArrowDown)`
  height: 25px;
  margin-right: 12px;
`;

const Content = styled.div`
  padding: 20px 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Player = styled.div`
  position: relative;
  display: flex;
`;

const MetaInfo = styled(Heading3)`
  margin-bottom: 30px;
`;

const Video = ({id}) => {
    const route = useParams()
    const [player, setPlayer] = useState(null);
    const {videoId} = route;
    const data = useSelector(state => {
        const {videos: {files: videos}} = state;
        return videos.find(video => video.playoutId === videoId);
    });

    useEffect(() => {
        (function () {
            let _js3qi = setInterval(function () {
                if (typeof js3q !== 'undefined') {
                    clearInterval(_js3qi);
                    window['js3qVideoPlayer'] = null;
                    window['js3qVideoPlayer'] = new window['js3q']({
                        dataid: `${id}`,
                        container: 'player1',
                        autoplay: false,
                        allowmutedautoplay: false,
                        width: '100%',
                        layout: 'default',
                        tintColor: '#005854',
                        controlBarAutoHide: true,
                        debug: true,
                        locale: 'en',
                        //defaultCC: 'eng',
                        defaultAudioLanguage: 'en',
                        tracking: false,
                        fullscreenOnOrientationChange: true,
                        labels: {
                            de: {
                                ad: 'Hinweis',
                            },
                            en: {
                                ad: 'Disclaimer',
                            },
                        },
                    });
                }
            }, 10);
        })();
    })

    const navigate = useNavigate()
    const stopPlayer = () => {
        window.js3qVideoPlayer.pause();
        setTimeout(() => {
            navigate('/');
        }, 100)
    }

    const {title, description, speakers, duration, downloadTitle, downloadURI} = data;

    return (
        <Cover>
            <Container>
                <Header>
                    <CloseX onClick={stopPlayer}/>
                </Header>
                <Scrollable>
                    <Player id="player1" />
                    <Content>
                        <MetaInfo>{`${speakers}, ${duration} ${duration > 1 ? "mins" : "min"}`}</MetaInfo>
                        <FeaturedHeading>{title}</FeaturedHeading>
                        <FeaturedParagraph>{description}</FeaturedParagraph>
                        {
                            downloadTitle !== "" && downloadURI !== "" ?
                                <ExternalLinkButton href={downloadURI} target="_parent" download><DownloadArrow />{downloadTitle}</ExternalLinkButton>
                                : null
                        }
                    </Content>
                </Scrollable>
            </Container>
        </Cover>
    )
}

export default Video;
