import axios from "axios";
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {API, EVENTS, VIDEOS} from "../../constants";

function* loadFileIds(action) {
    axios.defaults.headers.common['X-AUTH-APIKEY'] = process.env.REACT_APP_API_KEY;
    axios.defaults.headers.common['accept'] = `application/json`;
    try {
        const project = yield call(axios, `${API.URL}/api/v2/projects/${API.PROJECT_ID}/files?IncludeDeleted=false&IncludeMetadata=true&IncludePlayouts=true&IncludeProperties=true&ReleaseStatus=published`);
        const {data: {Files: files}} = project;
        const filtered = files.filter(file => file.IsFinished === true).map((file) => {
            const {Metadata, Metadata: {CustomMetadata}, Playouts, Properties} = file;
            const {Title: title, Description: description, IsPublicAt: recordDate, StandardFilePicture: {ThumbURI: thumbURI, URI: imageURI}} = Metadata;
            const {
                Category: category,
                Speakers: speakers,
                Featured: featured,
                EventType: eventType,
                EventTitle: eventTitle,
                EventDay: eventDay,
                DownloadTitle: downloadTitle,
                DownloadURI: downloadURI,
                ShortDescription: shortDescription
            } = CustomMetadata;
            const playoutId = Playouts[0].Playout.Id;
            const {Length: length} = Properties;
            const duration = Math.ceil(length / 60);
            return {
                title,
                description,
                category,
                speakers,
                eventType,
                eventTitle,
                eventDay: typeof(eventDay) === 'string' ? parseInt(eventDay) : 1,
                recordDate,
                featured: featured === "Yes",
                playoutId,
                duration,
                thumbURI,
                imageURI,
                downloadTitle,
                downloadURI,
                shortDescription,
            }
        });
        const events = filtered.map(video => {
            const {category, eventDay, eventType, eventTitle} = video;
            return {
                eventDay,
                eventType,
                eventTitle,
                category,
            }
        })
        yield all(events.map(event => put({type: EVENTS.ADD, payload: event})))
        yield put({type: VIDEOS.LOAD_SUCCESS, payload: filtered})
    } catch (e) {
        //console.log(e)
        yield put({type: VIDEOS.LOAD_ERROR});
    }
}

function* initWatchers() {
    //console.log(process.env)
    yield takeEvery(VIDEOS.LOAD, loadFileIds);
    yield put({type: VIDEOS.LOAD});
}

export default initWatchers;
