const STATE = {
    eventSelection: {
        type: "GLS",
        title: "GLS Live"
    }
}

function configReducer(state = STATE, action) {
    return state
}

export default configReducer
