import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga'
import {configureStore} from "@reduxjs/toolkit";
import reducers from "./reducers";
import videos from './videos/saga'

const sagaMiddleWare = createSagaMiddleware();

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(sagaMiddleWare),
})

function* initSagas() {
    yield all([
        videos()
    ])
}

sagaMiddleWare.run(initSagas)

export default store;
