import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import {Provider} from 'react-redux';
import {ThemeProvider} from "styled-components";
import store from './store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Video from "./views/Video";

const theme = {
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
    }
}
const Redirect = () => {
    //window.location.replace('https://gls-live.com/dutube')
    return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        {/*{ window.location !== window.parent.location ?*/}
        <Provider store={store}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <Routes>
                        <Route path="/" element={<App/>}>
                            <Route path="video" element={<Video/>}>
                                <Route path=":videoId" element={<Video/>}/>
                                <Route index element={<Video/>}/>
                            </Route>
                        </Route>
                    </Routes>
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
        /*: <Redirect/> }*/
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
