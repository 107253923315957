import {Link} from "react-router-dom";
import {BounceLoader} from "react-spinners";
import styled, {keyframes} from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Page = styled.div`
  animation-name: ${fadeInAnimation};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  padding-bottom: 60px;
`;

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #005854;
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-right: ${props => props.last ? "0" : "0.5em"};
  padding: 10px 24px;
  border: #005854 solid thin;
  ${breakpoint('sm')` // 28 : 36
        font-size: 17px;
    `}
  ${breakpoint('xl')` // 30 : 36
        font-size: 18px;
    `}
`;

const ExternalLinkButton = styled.a`
  display: flex;
  position: relative;
  align-items: center;
  background-color: #005854;
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-right: ${props => props.last ? "0" : "0.5em"};
  margin-top: 16px;
  padding: 10px 24px;
  border: #005854 solid thin;
  ${breakpoint('sm')` // 28 : 36
        font-size: 17px;
    `}
  ${breakpoint('xl')` // 30 : 36
        font-size: 18px;
    `}
`;

/*const Button = styled.button`
    display: flex;
    align-items: center;
    background: ${props => props.primary ? "#005854" : "white"};
    color: ${props => props.primary ? "white" : "black"};
    font-family: 'Ubuntu', sans-serif;
    font-size: 15px;
    margin-right: ${props => props.last ? "0" : "0.5em"};
    padding: 0.5em 1.875em;
    border-width: 0;
    ${breakpoint('sm')` // 28 : 36
        font-size: 16px;
    `}
    ${breakpoint('xl')` // 30 : 36
        font-size: 17px;
    `}
`;*/

const Heading1 = styled.h1`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  //text-transform: uppercase;
  font-size: 24px; // 24 : 28
  ${breakpoint('sm')` // 28 : 36
        font-size: 28px;
    `}
  ${breakpoint('xl')` // 30 : 36
        font-size: 38px;
    `}
`;

const Heading3 = styled.h3`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 16px;
  ${breakpoint('sm')` // 18
        font-size: 17px;
    `}
  ${breakpoint('xl')` // 24
        font-size: 18px;
    `}
`;

const Paragraph = styled.p`
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  ${breakpoint('sm')` // 16
        font-size: 15px;
    `}
  ${breakpoint('xl')` // 17
        font-size: 16px;
    `}
`;

const BounceSpinner = ({className}) => {
    return (
        <div className={className}>
            <BounceLoader loading={true} size={32} color="white" speedMultiplier={2}/>
        </div>
    )
}

const Spinner = styled(BounceSpinner)`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;


export {Page, Button, ExternalLinkButton, Heading1, Heading3, Paragraph, Spinner};
