import {combineReducers} from "@reduxjs/toolkit";
import videos from './videos/reducer';
import events from './events/reducer';
import window from './window/reducer';
import config from './config/reducer';

const reducers = combineReducers({
    events,
    videos,
    window,
    config,
});

export default reducers;
