import {WINDOW} from "../../constants";

const STATE = {
    width: 0,
    height: 0,
}

function windowReducer(state = STATE, action) {
    const {type, payload} = action;
    switch (type) {
        case WINDOW.RESIZE:
            const {width, height} = payload;
            return {...state, width, height}
        default:
            return state
    }
}

export default windowReducer
