import useResizeObserver from "@react-hook/resize-observer";
import {Play} from '@styled-icons/bootstrap';
import React, {useEffect, useLayoutEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import styled from 'styled-components';
import {Button, Page, Spinner} from "./components/BaseComponents";
import {
    FeatureContentContainer,
    FeaturedHeading,
    FeaturedParagraph,
    FeaturedSpeaker,
    FeatureSlide,
    FeatureSlider
} from "./components/FeatureSlider";
import {Section, SectionHeader, SectionList, SectionListItem, Sections} from "./components/Sections";
import {setDimension} from "./store/window/actions";
import Header from "./views/Header";
import Video from "./views/Video";

const WhitePlay = styled(Play)`
  height: 30px;
  margin-right: 5px;
`;

const useSize = (target) => {
    const dimensions = useSelector(state => {
        const {window} = state;
        return window;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        const {current} = target;
        if(current !== null) {
            const {width, height} = current.getBoundingClientRect();
            dispatch(setDimension({width, height}))
        }
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => {
        const {width, height} = entry.contentRect;
        dispatch(setDimension({width, height}))
    })
    return dimensions;
}

const App = () => {
    const routerParams = useParams()
    const {videoId} = routerParams;
    const isLoading = useSelector(state => {
        const {videos: {loading}} = state;
        return loading;
    });
    const featured = useSelector(state => {
        const {videos: {files: videos}} = state;
        return videos.filter(video => video.featured === true);
    });
    const sections = useSelector(state => {

        const {config: {eventSelection: {type: selectedType, title: selectedTitle}}, events: eventTypes, videos: {files: videos}} = state;
        const result = [];

        videos.sort((a, b) => {
            return new Date(b.recordDate) - new Date(a.recordDate);
        })
            //const eventType = eventTypes.find(el => el.type === selectedType);
        eventTypes.forEach(eventType => {
            const {events, type} = eventType;
                //const event = events.find(el => el.title === selectedTitle);
                events.forEach(event => {
                    const {title, dayCount, categories} = event;
                    let videoCategories = [...categories];
                    videoCategories.sort();
                    for (let day = 1; day <= dayCount; day++) {
                        videoCategories.forEach(category => {
                            const selectedVideos = videos.filter(video =>
                                video.eventType === type &&
                                video.eventDay === day &&
                                video.eventTitle === title &&
                                video.category === category
                            ).reverse()
                            if(selectedVideos.length > 0) {
                                result.push({
                                    title: `${title}: Day ${day} - ${category}`,
                                    videos: selectedVideos,
                                })
                            }
                        })
                    }
                })
        })
        return result;
    });

    const pageRef = useRef(null);
    useSize(pageRef);

    return (
        <div ref={pageRef}>
            {
                isLoading ?
                    <Spinner/> :
                    <Page ref={pageRef}>
                        {
                            featured.length > 0 ? (
                                <FeatureSlider>
                                    {
                                        featured.map(video => {
                                            const {imageURI, playoutId, title, shortDescription, speakers, duration} = video;
                                            return (
                                            <FeatureSlide src={imageURI} key={playoutId}>
                                                <FeatureContentContainer>
                                                    <p>GLS Live</p>
                                                    <FeaturedHeading>{title}</FeaturedHeading>
                                                    <FeaturedParagraph>{shortDescription}</FeaturedParagraph>
                                                    <FeaturedSpeaker>{`${speakers}, ${duration} ${duration > 1 ? "mins" : "min"}`}</FeaturedSpeaker>
                                                    <Button to={`/video/${playoutId}`}><WhitePlay/> Watch
                                                        now</Button>
                                                </FeatureContentContainer>
                                            </FeatureSlide>
                                        )})
                                    }
                                </FeatureSlider>
                            ) : null}
                        <Header/>
                        <Sections>
                            {
                                sections.map(section => {
                                    const {title, videos} = section;
                                    return (
                                        <Section key={title}>
                                            <SectionHeader>{title}</SectionHeader>
                                            <SectionList>
                                            {
                                                videos.map(video => {
                                                    const {title, playoutId} = video;
                                                    return (
                                                        <SectionListItem key={playoutId} data={video}>{title}</SectionListItem>
                                                    )
                                                })
                                            }
                                            </SectionList>
                                        </Section>
                                    )
                                })
                            }
                        </Sections>
                        {
                            videoId !== undefined ? <Video id={videoId}/> : null
                        }
                    </Page>
            }
        </div>

    );
}

export default App;
