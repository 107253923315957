const API = {
    URL: `https://sdn.3qsdn.com`,
    PROJECT_ID: '54547',
};

const VIDEOS = {
    LOAD: 'VIDEOS_LOAD',
    LOAD_SUCCESS: 'VIDEOS_LOAD_SUCCESS',
    LOAD_ERROR: 'VIDEOS_LOAD_ERROR',
    BASE_URL: `${window.location.origin}`,
};

const EVENTS = {
    ADD: 'EVENTS_ADD',
};

const WINDOW = {
    RESIZE: 'WINDOW_RESIZE',
}

export { API, VIDEOS, EVENTS, WINDOW };
